<template>
   <div class="container">

      <div class="collectionList list">
         <div class="item" :class="{active:collectionNameActive==item}" v-for="(item,index) in collectionNameList" :key="index" @click="clickCollection(item)">{{item}}</div>
      </div>

      <div class="stockList list">
         <div class="item" :class="{active:stockActive.code==item.code}" v-for="(item,index) in stockList" :key="index" @click="clickStock(item)">
            <div class="name">{{item.name}}</div>
            <div class="code">{{item.code}}</div>
         </div>
      </div>
      <div class="tickerData">
         <!-- <div id="tickerDataChart" style="width:1000px;height: 500px;"></div> -->
         <div id="tickerDataChart" style="width:100%;height:100%;"></div>
      </div>

   </div>
</template>

<script>

import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts)


export default {
    data(){
      return {
         collectionNameList:[],
         collectionNameActive:"",
         stockList:[],
         stockActive:{},
         tickerDataChart:null,
         chartPriceSeriesData:{}, // 图表数据：价格
         chartVolumeSeriesData:{},// 图表数据：交易量

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接",  // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{
      // 集合列表项点击事件
      clickCollection(item){
         this.collectionNameActive = item;
         this.webSocket.send(JSON.stringify({
            operationType:'tickerStockList',
            collectionName:item
         }));
      },
      // 股票列表项点击事件
      clickStock(item){
         this.stockActive = item;
         this.webSocket.send(JSON.stringify({
            operationType:'tickerStockList',
            code:item.code,
            collectionName:this.collectionNameActive
         }));
      },
      // 更新图表
      updateChart(){

         if(this.tickerDataChart){
            this.tickerDataChart.destroy()  // 销毁原有的图表
         }
         this.tickerDataChart = Highcharts.stockChart('tickerDataChart', {
            chart: {
               zoomType: null,
               // pinchType: null
            },
            rangeSelector: {
               selected: 4
            },
            yAxis: {
               labels: {
                  formatter: function () {
                     return (this.value > 0 ? ' + ' : '') + this.value + '%';
                  }
               },
               plotLines: [{
                  value: 0,
                  width: 2,
                  color: 'silver'
               }]
            },
            plotOptions: {
               series: {
                  compare: 'percent',
                  showInNavigator: true
               }
            },
            tooltip: {
               // formatter:function () {
               //    return "aa";
               // },
               pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
               valueDecimals: 2,
               followTouchMove: false,
               split: true
            },
            series:[
               this.chartPriceSeriesData,
               this.chartVolumeSeriesData
            ]
         });
      },
      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               if(data.collectionNameList.length > 0){
                  that.collectionNameList = data.collectionNameList.sort().reverse();
                  that.collectionNameActive = data.collectionNameList[0];
               }else if(data.stockList.length > 0){
                  that.stockList = data.stockList;
                  that.stockActive = data.stockList[0];
               }else{
                  if(data.dataList.length > 0){
                     let obj = data.dataList[0];
                     that.chartPriceSeriesData = {
                        name:"价格",
                        data:[]
                     };
                     that.chartVolumeSeriesData = {
                        name:"交易量",
                        data:[]
                     }
                     for(let i=0;i<obj.data.length;i++){
                        let hours8 =8 * 3600 // 图表默认以0时区显示时间，此处加8小时进行较正
                        that.chartPriceSeriesData.data.push([(obj.data[i].t+hours8)*1000 ,obj.data[i].p])
                        that.chartVolumeSeriesData.data.push([(obj.data[i].t+hours8)*1000,obj.data[i].v])
                        console.log("序号:"+ obj.data[i].se +", 原始时间:" + obj.data[i].t + ", 时间:" + that.$common.timestampToTime(obj.data[i].t) + ", 价:" + obj.data[i].p + ", 量:" + obj.data[i].v)
                     }
                     that.updateChart();
                  }else{
                     that.tickerData  = null;
                  }
                  
               }
               
            } catch(e) {
               console.log(evt.data);
               that.webSocket.send(JSON.stringify({
                  operationType:'tickerStockList'
               }));
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
   
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:row;
}

/* 公共列表样式 */
.list{
   display:flex;
   flex-direction: column;
   border-right: 1px solid rgb(223, 223, 223);
   padding:8px 0px;
   overflow:auto;
}
.list>.item{
   display:flex;
   flex-direction: column;
   padding:4px 10px;
   cursor: pointer;
}
.list>.item:hover{
   background:rgb(244, 244, 244);
}
.list>.item.active{
   background:rgb(230, 230, 230);
   font-weight: bold;
}


/* 集合列表 */
.collectionList{
   width:200px;
   min-width:200px;
   max-width:200px;
}

/* 股票列表 */
.stockList{
   width:300px;
   min-width:300px;
   max-width:300px;
}
.stockList>.item>.name{
   overflow: hidden;
   text-overflow: ellipsis; /* 文本溢出元素时省略号 */
   white-space: nowrap;     /* 规定段落中的文本不换行 */
}
.stockList>.item>.code{
   font-size:12px;

}

/* 逐笔数据 */
.tickerData{
   display:flex;
   flex-grow: 1
}
</style>